<template>
  <div class="login _flex _flex-center">
    <div class="login-content">
      <el-image :src="logo" class="login-content__logo"></el-image>
      <div class="login-content__title _font-size__heading2 _font-weight__600">
        Sign in to Radio Tower Monitoring System
      </div>

      <component class="login-content__box" :is="componentName" @switchComponent="handleSwitch" />

      <div class="login-content__copyright _font-size__sub__heading">
        Copyright© 2022 RaSpect
        <br />
        RaSpect Intelligence Inspection Limited
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions, import/no-unresolved
import logo from '@/assets/logo1.png'
import { Decrypt } from '@/utils/ASE-secret'
import LoginForm from './components/login-form'
import ForgetPwd from './components/forget-password'
import { TYPE_ENUM } from './components/index'

export default {
  components: {
    LoginForm,
    ForgetPwd
  },
  data() {
    return {
      logo,
      componentName: 'LoginForm'
    }
  },
  created() {
    const { params, type } = this.$route.query
    // 重置密码
    if (type === TYPE_ENUM.UPDATE_PWD) {
      this.componentName = 'ForgetPwd'
    }
    // 修改密码
    if (params) {
      const paramStr = Decrypt(params.replaceAll(' ', '+'))
      const reg = new RegExp(`${TYPE_ENUM.FORGET_PWD}`)
      // 修改密码和重置密码类型
      if (reg.test(paramStr)) {
        this.componentName = 'ForgetPwd'
      }
    }
  },
  methods: {
    handleSwitch(val) {
      this.componentName = val
    },
    handleLogin() {
      this.$store
        .dispatch('adminLogin', this.loginForm)
        .then(() => {
          this.loading = false
          this.$router.push({ path: '/' })
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.login {
  width: 100vw;
  height: 100vh;

  &-content {
    width: 480px;
    > * {
      display: block;
      margin: 0 auto;
    }
    &__logo {
      width: 190px;
      height: 60px;
      margin-bottom: 32px;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 77px;
    }

    ::v-deep &__box {
      position: relative;
      margin-bottom: 50px;
      height: 380px;
      padding: 32px 45px 0;
      background: #ffffff;

      .el-form {
        margin-bottom: 40px;
        .el-form-item + .el-form-item {
          margin-top: 40px;
        }
        .el-form-item {
          &__error {
            color: #cc2b1e !important;
            display: flex;
            align-items: center;
            &::before {
              content: '\e6c9';
              font-family: element-icons !important;
              font-size: 16px;
              margin-right: 3px;
            }
          }

          .el-input__inner {
            line-height: 44px;
            height: 44px;
            padding: 0;
            font-size: 17px;
            border: none;
            border-bottom: 1px solid var(--color-info-light-7);
            border-radius: 0;
          }

          .el-input__suffix {
            &-inner {
              display: flex;
              align-items: center;
              height: 100%;
              img {
                width: 21px;
                display: block;
              }
            }
          }
        }
      }

      &__operate {
        margin-bottom: 40px;
        line-height: 30px;
      }

      &__remember {
        & .is-checked .el-checkbox__inner {
          border-color: var(--color-primary) !important;
        }
        & .el-checkbox__inner {
          border-width: 2px;
          border-style: solid;
          border-color: var(--color-info);
        }
      }

      &__sign-in {
        width: 100%;
        height: 36px;
        padding: 0 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
      }

      &__sign-up {
        width: 100%;
        text-align: center;
        margin-top: 10px;
      }
    }

    ::v-deep &__forget {
      padding-top: 110px;
      &__label {
        width: 100%;
        font-size: 20px;
        line-height: 52px;
        padding-left: 20px;
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: 1px solid var(--color-info-light-7);
      }
      &__send {
        height: 36px;
        padding: 0 20px;
        width: 100%;
      }
      &__send-success {
        margin-top: 10px;
      }
      &__to-login {
        width: 100%;
        margin-top: 20px;
        text-align: center;
      }
    }

    ::v-deep .rules-error {
      color: #cc2b1e !important;
      font-size: 12px;
      padding-left: 15px;
      > span {
        display: block;
        &::before {
          content: '·';
          padding: 6px;
          font-weight: bold;
        }
      }
    }

    &__copyright {
      text-align: center;
      width: 280px;
      line-height: 24px;
    }
  }
}
</style>
